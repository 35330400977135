
// import './modules/foundation/en.js';
import './modules/foundation/s-img.js';
// import './modules/foundation/wf-loader.js';

export let M = {
    spSize: 768,
    isDev: import.meta.env.VITE_ENV ? true : false,
    scrollTop: 0,
    scrollIsDown: false,
    deviceSize: 'pc',
    baseDomain: import.meta.env.VITE_PROTOCOL + import.meta.env.VITE_BASE_URL,
    apiDomain: import.meta.env.VITE_PROTOCOL + import.meta.env.VITE_API_BASE_URL,
    grecaptchaSiteKey: import.meta.env.VITE_GOOGLE_RECHAPTCHA_SITE_KEY,
}

console.log(import.meta.env.VITE_BASE_URL);
console.log(import.meta.env.VITE_API_BASE_URL);