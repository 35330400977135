/*! Yohei Hashimoto's En System
 * https://yhashimoto.com
 * Copyright Yohei Hashimoto */

const sImgLoadedDelay = 0;

document.addEventListener('DOMContentLoaded', function () {
    const sImgs = document.querySelectorAll('.img[data-progressive="1"]');

    // Set Progressive Images
    sImgs.forEach(function(el) {
        const loadedImg = el.querySelector('.-loaded-img');

        if (el.dataset.type === 'video' && loadedImg instanceof HTMLVideoElement) {

            // elがvideo要素の場合の処理
            loadedImg.addEventListener('loadeddata', function() {
                setTimeout(function() {
                    el.dataset.loaded = 1;
                    // console.log('loaded progressive video');
                }, sImgLoadedDelay);
            });

            // ビデオがすでに読み込まれている場合の処理
            if (loadedImg.readyState >= 2) {
                // console.log('checked progressive video');
                loadedImg.dispatchEvent(new Event('loadeddata'));
            }

            setTimeout(function() {
                el.dataset.loaded = 1;
            }, 3000);

            // Image
        } else {
            const img = el.querySelector('img'); // `<img>` 子要素を取得
    
            if (img) {
                // `load` イベントの監視
                img.addEventListener('load', function () {
                    setTimeout(() => {
                        el.dataset.loaded = 1; // ロード済みフラグを設定
                        //console.log('Image loaded successfully');
                    }, sImgLoadedDelay);
                });
    
                // すでに読み込まれている場合
                if (img.complete && img.naturalWidth > 0) {
                    img.dispatchEvent(new Event('load'));
                }
    
                // Fallback: 3秒後にロード完了とみなす
                setTimeout(() => {
                    if (!el.dataset.loaded) {
                        el.dataset.loaded = 1;
                        //console.log('Image loading fallback triggered');
                    }
                }, 3000);
            } else {
                //console.warn('No <img> found within .loaded-img');
            }
        }
    });
});
